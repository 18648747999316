import React from "react";
import { Route, Routes } from "react-router-dom";
import MyComponentMobile from "./MyComponentMobile"; // Import your other components as needed
import Privacy from "./components/mobile/Privacy"; // Adjust the path as necessary
import Terms from "./components/mobile/Terms"; // Adjust the path as necessary

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MyComponentMobile />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
};

export default AppRoutes;
