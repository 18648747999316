import React, { useEffect, useState } from "react";
import "./FaqSection.css";

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showMore, setShowMore] = useState(false); // State to control showing more FAQs
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Initialize isMobile state

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleAnswer = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqData = [
    {
      question: "What is Anthropod AI",
      answer:
        "Anthropod is a customizable AI call analytics tool that helps businesses audit calls, identify potential churn, and uncover missed high-value opportunities. It seamlessly integrates with any CRM within 48 hours. With options for self-configuration or assisted setup, it can also automate workflows by auto CRM updates, setting follow-ups, triggering communications, and marking conversions.",
    },
    {
      question: "How many languages does Anthropod Support?",
      answer:
        "Anthropod supports the top 12+ Indian languages and over 90 international languages, providing extensive multilingual capabilities for its users.",
    },
    {
      question: "How can I know more about Anthropod AI?",
      answer: "You can schedule a call by booking a demo on our website.",
    },
    {
      question: "Can I implement this in any team of any size?",
      answer:
        "Yes. Since this is an AI solution, it is cost-effective and can be implemented at scale. The good part is it's standardized and can be seamlessly integrated.",
    },
    {
      question:
        "How can I use Anthropod AI to improve the quality of my customer support team?",
      answer:
        "Anthropod analyzes customer communications and extracts actionable insights on agent, customer campaigns, product packaging, etc. These insights can be used to train and improve the quality of our contact center agents.",
    },
    {
      question: "Can I implement compliance checks on my customer calls?",
      answer:
        "Yes, you can implement compliance checks on your customer calls using Anthropod AI. The AI can be customized to flag specific issues, such as misleading statements, disposition manipulation, force selling, rude or unprofessional behavior, and inattentiveness leading to call drops.",
    },
    {
      question: "Can I customize Anthropod AI?",
      answer:
        "Yes, this solution is customizable for every client irrespective of size.",
    },
    {
      question: "Will Anthropod AI help with technical integration?",
      answer:
        "Anthropod has a team of technical workforce who can assist the clients with the integrations with IVR, CRM, Chatbots, Communication channels, etc.",
    },
  ];

  const moreFaqData = [
    {
      question:
        "What is the AI-powered real-time Call Analytics that Anthropod provides?",
      answer:
        "AI-powered call analytics uses advanced artificial intelligence & business frameworks to monitor, analyze, and provide insights during live customer interactions from all communication channels. This involves processing speech or text data in real-time to assist the call centre advisors in responding well, identifying winning patterns, and improving the overall customer experience.",
    },
    {
      question: "How Anthropod’s AI Call Analytics improves customer support?",
      answer:
        "Anthropod AI improves customer service by providing deeper insights into customer behaviours, preferences, and pain points. It exactly identifies unhappy customers and the reasons for the same, enabling leaders to implement strategies to retain unhappy customers and reduce churn. Anthropod AI also assists with personalising customer communications improving the CSAT score.",
    },
    {
      question: "Key Benefits of Real-Time AI Call Auditing for Businesses?",
      answer:
        "Real-time AI call auditing allows businesses to audit interactions (Chats or Calls) on an ongoing basis as they are in progress. Anthropod AI provides detailed reports on agent compliance, customer sentiment, and missed opportunities, empowering leaders to take daily actions to improve productivity.",
    },
    {
      question: "How does Agent Assist feature work?",
      answer:
        "Anthropod analyses the call/chat data and empowers call advisors to improvise their pitch based on customer sentiment in real-time. It provides specific customer insights that help in identifying hot leads so that the agents can do better prioritisation. In terms of agent performance, it provides feedback based on speech parameters for agents to communicate well. It also highlights script adherence, sentiment shifts, and the agent’s emotional changes on the call. Anthropod AI automates the process, enabling agents to respond quickly and effectively.",
    },
    {
      question:
        "How Anthropod AI Call Analytics helps Businesses reduce Customer Churn?",
      answer:
        "Anthropod AI helps businesses reduce customer churn by providing insights on customer sentiment and identifying dissatisfied customers daily. This allows businesses to address these concerns proactively, improving retention and customer satisfaction. It also highlights probable resolution techniques that can be adopted by the agents.",
    },
    {
      question:
        "How Anthropod AI Call Analytics tool detects missed Sales Opportunities?",
      answer:
        "Anthropod AI identifies missed sales opportunities by analyzing conversations for certain cues such as customer interest or not, product mentions or pricing discussions, upsell potential, follow-ups, etc. These highlights assist the call centre agents in identifying opportunities that could be capitalised on by making some changes in the pitch or offer, thereby improving sales and revenue.",
    },
    {
      question: "How AI Call Analytics can help improve the LTV of customers?",
      answer:
        "Anthropod AI’s insights from communication data across platforms assist in detecting unhappy and dissatisfied customers while identifying potential churn triggers. Businesses can intervene to retain the same customer, thereby improving chances of cross-sells and upsells impacting the LTV.",
    },
    {
      question: "What Speech Parameters Does Anthropod Provide?",
      answer:
        "Anthropod AI’s speech parameters include a range of customizable aspects such as tone, sentiment analysis, speech clarity, script adherence, confidence, rudeness, and conversation flow. These insights help improve both agent performance and customer satisfaction.",
    },
    {
      question: "How Does Workflow Automation Help Improve Agent Productivity?",
      answer:
        "Many repetitive tasks such as CRM updates, call summaries, and follow-up actions take up 60% of the agent’s time. Anthropod analyses communication data to add a plethora of customer data, both important and unimportant to the CRM. This frees up agents' time to focus on higher-value activities, thereby improving their productivity and reducing errors.",
    },
    {
      question:
        "Will Anthropod just do Audits or provide suggestions to Improve Agent Performance?",
      answer:
        "Anthropod AI goes beyond simple call audits. It provides actionable recommendations and AI-based suggestions customised to the customer conversation. This helps agents improve their performance through personalized feedback and real-time guidance.",
    },
    {
      question: "What is Real-Time Agent Assist from Anthropod?",
      answer:
        "The real-time agent assist feature is a copilot that provides live suggestions and assistance to agents during customer interactions. Anthropod AI offers this feature by analyzing ongoing calls and suggesting improvements or next steps to ensure better outcomes. However, Anthropod responses are tuned to deliver post-contextual information from the conversation.",
    },
  ];

  return (
    <div style={{ background: "#F6F6F6", width: isMobile ? "100%" : "" }}>
      <div
        style={{
          color: "black",
          textAlign: "center",
          fontSize: isMobile ? "14px" : "25px",
          fontWeight: "700",
          padding: "32px 8px 16px 0px", // 2rem = 32px, 0.5em = 8px, 1em = 16px, 0em = 0px
        }}
      >
        <h2>FAQ's on Anthropod </h2>
      </div>
      <div className="faq-container" style={{ padding: "1px 0 40px 0" }}>
        {/* Render the original FAQs */}
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="faq-item"
            style={{
              margin: "10px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                width: window.innerWidth < 760 ? "90%" : "75%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => toggleAnswer(index)}
            >
              <h3
                style={{
                  fontSize: window.innerWidth > 760 ? "20px" : "18px",
                  marginLeft: "15px",
                }}
              >
                {faq.question}
              </h3>
              <span
                style={{
                  color: "#c3c3c3",
                  fontSize: "28px",
                  display: "flex",
                }}
              >
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>

            {activeIndex === index && (
              <div
                style={{
                  padding: "10px",
                  marginLeft: "16px", // 2em = 32px
                  background: "#f9f9f9",
                  borderRadius: "8px",
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                  width: window.innerWidth > 760 ? "76%" : "",
                }}
              >
                <p
                  style={{
                    fontSize: window.innerWidth > 760 ? "18px" : "14px",
                    margin: 0,
                    background: "#EFEFEF",
                    borderRadius: "10px",
                    padding: "10px",
                    width: window.innerWidth < 760 ? "94%" : "",
                  }}
                >
                  {faq.answer}
                </p>
              </div>
            )}
          </div>
        ))}
        {/* "See More" Button */}
        {!showMore && (
          <div
            onClick={() => setShowMore(true)}
            style={{
              cursor: "pointer",
              textAlign: "center",
              padding: "20px",
              margin: "10px auto", // Centers the div
              background: "#e0e0e0",
              borderRadius: "8px",
              transition: "background-color 0.3s",
              width: "80%", // Change this to your desired width
              maxWidth: "600px", // Optional: Set a max width for larger screens
            }}
            onMouseEnter={(e) => (e.currentTarget.style.background = "#d0d0d0")}
            onMouseLeave={(e) => (e.currentTarget.style.background = "#e0e0e0")}
          >
            <span style={{ fontSize: "20px" }}>See More</span>
            <span
              className="arrow-down"
              style={{ display: "inline-block", marginLeft: "8px" }}
            ></span>
          </div>
        )}
        {showMore && (
          <>
            {moreFaqData.map((faq, index) => (
              <div
                key={index}
                className="faq-item"
                style={{
                  margin: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    background: "white",
                    borderRadius: "8px",
                    width: window.innerWidth < 760 ? "90%" : "75%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleAnswer(faqData.length + index)} // Unique index for new FAQs
                >
                  <h3
                    style={{
                      fontSize: window.innerWidth > 760 ? "20px" : "18px",
                      marginLeft: "15px",
                    }}
                  >
                    {faq.question}
                  </h3>
                  <span
                    style={{
                      color: "#c3c3c3",
                      fontSize: "28px",
                      display: "flex",
                    }}
                  >
                    {activeIndex === faqData.length + index ? "-" : "+"}
                  </span>
                </div>

                {activeIndex === faqData.length + index && (
                  <div
                    style={{
                      padding: "10px",
                      marginLeft: "16px", // 2em = 32px
                      background: "#f9f9f9",
                      borderRadius: "8px",
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "flex-start",
                      width: window.innerWidth > 760 ? "76%" : "",
                    }}
                  >
                    <p
                      style={{
                        fontSize: window.innerWidth > 760 ? "18px" : "14px",
                        margin: 0,
                        background: "#EFEFEF",
                        borderRadius: "10px",
                        padding: "10px",
                        width: window.innerWidth < 760 ? "94%" : "",
                      }}
                    >
                      {faq.answer}
                    </p>
                  </div>
                )}
              </div>
            ))}

            {/* "See Less" Button */}
            <div
              onClick={() => setShowMore(false)}
              style={{
                cursor: "pointer",
                textAlign: "center",
                padding: "20px",
                margin: "10px auto", // Centers the div
                background: "#e0e0e0",
                borderRadius: "8px",
                transition: "background-color 0.3s",
                width: "80%", // Change this to your desired width
                maxWidth: "600px", // Optional: Set a max width for larger screens
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = "#d0d0d0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.background = "#e0e0e0")
              }
            >
              <span style={{ fontSize: "20px" }}>See Less</span>
              <span
                className="arrow-up"
                style={{ display: "inline-block", marginLeft: "8px" }}
              ></span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FaqSection;
