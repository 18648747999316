import React, { useEffect, useState } from "react";
import WidgetChangeData from "../../utils/widgetChangeData.js";
import WidgetResultComponent from "./WidgetResultComponent.js";
import WidgetTitleItemsComponent from "./WidgetTitleItemsComponent.js";

const WidgetChangeComponent = () => {
  const [titleHeadings] = useState(WidgetChangeData.titleHeadings);
  const [titleObjects] = useState(WidgetChangeData.titleObjects);
  const [currentWidget, setCurrentWidget] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Added isMobile state

  const handleChangeWidget = (e) => {
    setCurrentWidget(e);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        width: isMobile ? "100%" : "1840px",
        marginLeft: isMobile ? "16px" : "32px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: isMobile ? "block" : "flex",
        }}
      >
        <div
          style={{
            flex: isMobile ? "none" : "0.15",
            display: isMobile ? "none" : "",
          }}
        ></div>
        <WidgetTitleItemsComponent
          titleheadings={titleHeadings}
          titleobjects={titleObjects}
          prop_selected_widget={handleChangeWidget}
        />
        <WidgetResultComponent currWidget={currentWidget} />
        <div
          style={{
            flex: isMobile ? "none" : "0.15",
            display: isMobile ? "none" : "",
          }}
        ></div>
      </div>
    </div>
  );
};

export default WidgetChangeComponent;
